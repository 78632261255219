import React from 'react';
import Box from '../../utils/Box';
import Flex from '../../utils/Flex';
import Image from '../../utils/Image';
import Heading from '../../utils/Heading';
import FillIcon from '../../utils/FillIcon';
import Container from '../../utils/Container';
import useCompany from '../../hooks/useCompany';
import useCustomApp from '../../hooks/useCustomApp';

const WelcomePageContainer = ({title, info, children}) => {
  const {settings} = useCompany();
  const {welcomeLogoIsCentered} = useCustomApp();
  const logo =
    settings &&
    settings.logo &&
    settings.logo.sizes &&
    settings.logo.sizes.regular;
  return (
    <Container
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center">
      <Flex
        flexDirection="column"
        bg="white"
        p={6}
        borderRadius={0}
        width="100%"
        maxWidth="40rem"
        boxShadow={0}>
        {typeof logo !== 'undefined' ? (
          <Flex
            mb={6}
            height="7rem"
            justifyContent={welcomeLogoIsCentered ? 'center' : 'flex-start'}>
            <Image src={logo} height="100%" />
          </Flex>
        ) : (
          <FillIcon mb={6} size="largest" name="roger" />
        )}
        <Heading mb={6} variant={1}>
          {title}
        </Heading>
        <Box mb={3}>{info}</Box>
        {children}
      </Flex>
    </Container>
  );
};

export default WelcomePageContainer;
