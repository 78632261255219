import React from 'react';
import {useTheme} from 'styled-components';
import useCurrentUser from '../../hooks/useCurrentUser';
import Title from '../../utils/Title';
import Button from '../../utils/Button';

export const Logo = ({color}) => (
  <svg viewBox="0 0 219 186.4" width={24} height={24}>
    <g>
      <path
        fill={color}
        d="M111.2,180.2c-0.7,0-1.3,0-2,0c-0.6-0.3-1.2-0.5-1.8-0.8c-14.8-6.8-30.3-9.7-46.5-9.5
                    c-15.1,0.2-29.9,3.1-44.4,7.2c-4.8,1.4-6.6,0.6-8.9-3.7c0-44,0-88,0-132c1.5-3.4,4.1-4.5,7.7-4.3c3.5,0.2,7.1,0,10.6,0
                    c1.1,0,2.2,0,3.6,0c0-6,0-11.6,0-17.3c0-4.8,1.4-6.5,6.1-7.2c4-0.6,8-1.1,12-1.6c2.5,0,5.1,0,7.6,0c0.6,0.1,1.2,0.2,1.8,0.3
                    c4.3,0.6,8.6,0.8,12.9,1.7c15.9,3.3,29.7,10.4,40.1,23.3c0.1,0.2,0.4,0.3,0.6,0.5c4.9-5.8,10.5-10.6,17-14.2
                    c18.5-10.4,38.4-12.5,59-9.8c3.4,0.4,5.1,2.6,5.1,6.1c0,5.3,0,10.7,0,16c0,0.7,0,1.4,0,2.2c4.8,0,9.4,0,13.9,0
                    c4.9,0,6.9,2,6.9,6.9c0,18.1,0,36.1,0,54.2c0,24.3,0,48.7-0.1,73c0,1.6-0.5,3.5-1.5,4.8c-1.7,2.3-4.2,2.1-6.7,1.4
                    c-13.8-4-27.8-6.8-42.2-7.3C144.3,169.4,127.3,172.4,111.2,180.2z M180.3,134c0-36.9,0-73.7,0-110.7c-17.8-1.2-34.8,1-50.1,10.9
                    c-5.4,3.5-9.9,7.9-13.5,13.2c-0.5,0.8-0.9,2-0.9,2.9c0,34,0,68,0,102c0,0.5,0.1,1.1,0.2,1.9C135.6,141.2,156.3,132.4,180.3,134z
                     M104.5,153.1c0-0.8,0.1-1.2,0.1-1.6c0-33.9,0-67.7,0-101.6c0-0.9-0.3-2-0.8-2.8c-3.6-6-8.5-10.8-14.4-14.5
                    C76.8,24.7,63,22,48.5,22.4c-2.6,0.1-5.1,0.4-7.9,0.6c-0.2,37.3-0.4,74.3-0.6,111.4C63.6,133.8,85,139.3,104.5,153.1z
                     M100.6,164.3c-0.7-0.6-1.4-1.1-2.2-1.7c-18.7-13.7-39.9-18.3-62.7-16.7c-4.6,0.3-7-1.8-6.9-6.5c0.2-29.5,0.3-59.1,0.5-88.6
                    c0-0.8,0-1.5,0-2.3c-3.6,0-6.9,0-10.2,0c0,38.8,0,77.4,0,116.3C46.3,157.8,73.4,155.7,100.6,164.3z M191.6,48.5c0,0.9,0,1.7,0,2.5
                    c0,29.5,0,58.9,0,88.4c0,4.8-2.5,7.1-7.2,6.4c-7.2-1-14.3-1-21.5,0.2c-14.7,2.5-27.7,8.9-40,17c-0.2,0.2-0.5,0.4-1.1,1
                    c26.8-8.1,53-5.9,79.2,0.8c0-38.9,0-77.6,0-116.3C198,48.5,195,48.5,191.6,48.5z"
      />
    </g>
  </svg>
);

const label = 'eLibrary';
const token = 'JFb4pK6Vz1U490RB';
const baseUrl = 'https://vanmoer.bookboon.net/';

const ELibrary = ({...props}) => {
  const theme = useTheme();

  const {language} = useCurrentUser();

  const qs = new URLSearchParams({
    token,
  });

  // Transfer the user's language to the link
  if (['en', 'de', 'fr', 'nl'].includes(language)) {
    qs.append('lang', language);
  }

  const url = `${baseUrl}?${qs.toString()}`;

  return (
    <Button
      bg="vanmoerSecondary"
      onClick={() => {
        window.location.href = url;
      }}
      {...props}>
      <Logo color={theme.colors.white} />
      <Title variant={3} color="white" ml={5}>
        {label}
      </Title>
    </Button>
  );
};

export default ELibrary;
