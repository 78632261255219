import React from 'react';
import {Trans} from '@lingui/macro';
import {useHistory} from 'react-router-dom';
import Flex from '../../utils/Flex';
import Button from '../../utils/Button';
import Header from '../../utils/Header';
import MyProtime from './MyProtime';
import ProfilePageHeader from './ProfilePageHeaderWithState';
import ProfileDescription from './ProfileDescription';
import ProfileOtherSection from './ProfileOtherSection';
import ProfilePageContainer from './ProfilePageContainer';
import ProfileContactInformation from './ProfileContactInformation';
import ProfileNotificationSettings from './ProfileNotificationSettings';
import ProfileLanguageButtonWithState from './ProfileLanguageButtonWithState';
import ELibrary from './ELibrary';
import Arteel, {ARTEEL} from './Arteel';
import Page, {PageContentScrollable} from '../../utils/Page';
import Icon from '../../utils/Icon';
import Title from '../../utils/Title';

const ProfilePage = ({
  onLogout,
  channels,
  currentUser,
  userChannels,
  showMyProtime,
  showELibrary,
  onUpdateAvatar,
  onChangeLanguage,
  isUpdatingLanguage,
  onOpenPrivacyPolicy,
  onUpdateDescription,
  onUpdateUserChannels,
  isUpdatingDescription,
}) => {
  const {
    avatar,
    language,
    lastName,
    firstName,
    description,
    apiExporterUserConfigs: integrations,
  } = currentUser;
  const history = useHistory();
  const handleGoBack = () => {
    history.goBack();
  };
  const wrap = children => {
    if (!children) {
      return null;
    }

    return (
      <Flex alignItems="center" flexDirection="column" my={4}>
        {children}
      </Flex>
    );
  };

  let arteel;
  const arteelConfig = integrations?.find(
    ({driver}) => driver === ARTEEL,
  )?.config;

  if (arteelConfig) {
    arteel = <Arteel config={arteelConfig} />;
  }

  let myProtime;

  if (showMyProtime) {
    myProtime = <MyProtime />;
  }

  let eLibrary;

  if (showELibrary) {
    eLibrary = <ELibrary />;
  }

  return (
    <>
      <Header />
      <Page>
        <Flex flexDirection="row" justifyContent="space-between" pt={5} mb={3}>
          <Button
            variant="unstyled"
            onClick={handleGoBack}
            flexDirection="row"
            alignItems="center"
            pr={2}>
            <Icon name="chevronLeftLarge" strokeWidth={2.5} />
            <Title variant={4} color="primary">
              <Trans>Back</Trans>
            </Title>
          </Button>
        </Flex>
        <PageContentScrollable>
          <ProfilePageContainer>
            <ProfilePageHeader
              avatar={avatar}
              lastName={lastName}
              firstName={firstName}
              onUpdateAvatar={onUpdateAvatar}
            />
            {wrap(arteel)}
            {wrap(myProtime)}
            {wrap(eLibrary)}
            <ProfileDescription
              description={description}
              onUpdateDescription={onUpdateDescription}
              isUpdatingDescription={isUpdatingDescription}
              my={7}
            />
            <ProfileLanguageButtonWithState
              language={language}
              onChangeLanguage={onChangeLanguage}
              isUpdatingLanguage={isUpdatingLanguage}
              my={7}
            />
            <ProfileContactInformation user={currentUser} my={7} />
            <ProfileNotificationSettings
              channels={channels}
              userChannels={userChannels}
              onUpdateUserChannels={onUpdateUserChannels}
              user={currentUser}
              my={7}
            />
            <ProfileOtherSection
              onOpenPrivacyPolicy={onOpenPrivacyPolicy}
              my={7}
            />
            <Button bg="red" width="100%" my={7} onClick={onLogout}>
              <Trans>Logout</Trans>
            </Button>
          </ProfilePageContainer>
        </PageContentScrollable>
      </Page>
    </>
  );
};

export default ProfilePage;
