import React from 'react';
import {useTheme} from 'styled-components';
import Title from '../../utils/Title';
import Button from '../../utils/Button';

export const MyProtimeLogo = ({color}) => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m16.641 16-4.922-2.936V6.736"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.364 5.636A9 9 0 1 1 5.635 18.365 9 9 0 0 1 18.364 5.636"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const protimeUrl = 'https://vanmoer.myprotime.eu/';

const MyProtime = ({...props}) => {
  const theme = useTheme();

  return (
    <Button
      bg="vanmoer"
      onClick={() => {
        window.location.href = protimeUrl;
      }}
      {...props}>
      <MyProtimeLogo color={theme.colors.white} />
      <Title variant={3} color="white" ml={6}>
        MyProtime
      </Title>
    </Button>
  );
};

export default MyProtime;
